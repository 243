.border-xy{
        border-radius : 190px 0px 190px 87px
}
.border-flow-bite{
        border-radius: 210% 57% 25% 38% / 0% 27% 0% 42%;
        
}
.glass{
        background: rgba(232,251,180,0.25);
-webkit-backdrop-filter: blur(1px);
backdrop-filter: blur(1px);
border: 1px solid rgba(232,251,180,0.125);
}
/* shadow  */
.drop-c{
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.drop-auth{
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.drop{
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.drop-2{
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}
.drop-form{
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

/* shadow  */
/* fade  */
.fade-in-left {
	-webkit-animation: fade-in-left 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-left 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

 @-webkit-keyframes fade-in-left {
    0% {
      -webkit-transform: translateX(-50px);
              transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-left {
    0% {
      -webkit-transform: translateX(-50px);
              transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  /*  */
  /* forawrd base juction  */
  .fade-in-right {
	-webkit-animation: fade-in-right 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-right 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-6-3 2:9:22
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-right
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in-right {
    0% {
      -webkit-transform: translateX(50px);
              transform: translateX(50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-right {
    0% {
      -webkit-transform: translateX(50px);
              transform: translateX(50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  /* / */

  /* z */
.swing-in-top-fwd {
	-webkit-animation: swing-in-top-fwd 0.9s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
	        animation: swing-in-top-fwd 0.9s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}


 @-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}
@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}
.fade-in-top {
	-webkit-animation: fade-in-top 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-top 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}



/* ----------------------------------------------
 * Generated by Animista on 2023-6-4 16:0:47
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-top
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
/* image=coin */
.image-coin{

      -webkit-animation: mover 1s infinite  alternate;
    animation: mover 1s infinite  alternate;
}
.image-coin{
    -webkit-animation: mover 1s infinite  alternate;
    animation: mover 1s infinite  alternate;
}
@-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
/* z */
.swing-in-top-fwd {
	-webkit-animation: swing-in-top-fwd 0.9s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
	        animation: swing-in-top-fwd 0.9s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}


 @-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}
@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}
/* bounce */
.bounce-in-top {
	-webkit-animation: bounce-in-top 1.1s both;
	        animation: bounce-in-top 1.1s both;
}

@-webkit-keyframes bounce-in-top {
        0% {
          -webkit-transform: translateY(-500px);
                  transform: translateY(-500px);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
          opacity: 0;
        }
        38% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
          opacity: 1;
        }
        55% {
          -webkit-transform: translateY(-65px);
                  transform: translateY(-65px);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        72% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
        }
        81% {
          -webkit-transform: translateY(-28px);
                  transform: translateY(-28px);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        90% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
        }
        95% {
          -webkit-transform: translateY(-8px);
                  transform: translateY(-8px);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        100% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
        }
      }
      @keyframes bounce-in-top {
        0% {
          -webkit-transform: translateY(-500px);
                  transform: translateY(-500px);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
          opacity: 0;
        }
        38% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
          opacity: 1;
        }
        55% {
          -webkit-transform: translateY(-65px);
                  transform: translateY(-65px);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        72% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
        }
        81% {
          -webkit-transform: translateY(-28px);
                  transform: translateY(-28px);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        90% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
        }
        95% {
          -webkit-transform: translateY(-8px);
                  transform: translateY(-8px);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        100% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
        }
      }
      /*  Logout*/
      .slide-in-elliptic-top-fwd {
	-webkit-animation: slide-in-elliptic-top-fwd 0.9s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-elliptic-top-fwd 0.9s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes slide-in-elliptic-top-fwd {
        0% {
          -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
                  transform: translateY(-600px) rotateX(-30deg) scale(0);
          -webkit-transform-origin: 50% 100%;
                  transform-origin: 50% 100%;
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0) rotateX(0) scale(1);
                  transform: translateY(0) rotateX(0) scale(1);
          -webkit-transform-origin: 50% 1400px;
                  transform-origin: 50% 1400px;
          opacity: 1;
        }
      }
      @keyframes slide-in-elliptic-top-fwd {
        0% {
          -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
                  transform: translateY(-600px) rotateX(-30deg) scale(0);
          -webkit-transform-origin: 50% 100%;
                  transform-origin: 50% 100%;
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0) rotateX(0) scale(1);
                  transform: translateY(0) rotateX(0) scale(1);
          -webkit-transform-origin: 50% 1400px;
                  transform-origin: 50% 1400px;
          opacity: 1;
        }
      }
      .scale-in-ver-top {
	-webkit-animation: scale-in-ver-top 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-ver-top 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-7-10 14:53:22
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-ver-top
 * ----------------------------------------
 */
 @-webkit-keyframes scale-in-ver-top {
        0% {
          -webkit-transform: scaleY(0);
                  transform: scaleY(0);
          -webkit-transform-origin: 100% 0%;
                  transform-origin: 100% 0%;
          opacity: 1;
        }
        100% {
          -webkit-transform: scaleY(1);
                  transform: scaleY(1);
          -webkit-transform-origin: 100% 0%;
                  transform-origin: 100% 0%;
          opacity: 1;
        }
      }
      @keyframes scale-in-ver-top {
        0% {
          -webkit-transform: scaleY(0);
                  transform: scaleY(0);
          -webkit-transform-origin: 100% 0%;
                  transform-origin: 100% 0%;
          opacity: 1;
        }
        100% {
          -webkit-transform: scaleY(1);
                  transform: scaleY(1);
          -webkit-transform-origin: 100% 0%;
                  transform-origin: 100% 0%;
          opacity: 1;
        }
      }

      .slit-in-diagonal-2 {
	-webkit-animation: slit-in-diagonal-2 0.45s ease-out both;
	        animation: slit-in-diagonal-2 0.45s ease-out both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-7-11 15:12:21
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slit-in-diagonal-2
 * ----------------------------------------
 */
 @-webkit-keyframes slit-in-diagonal-2 {
        0% {
          -webkit-transform: translateZ(-800px) rotate3d(-1, 1, 0, -90deg);
                  transform: translateZ(-800px) rotate3d(-1, 1, 0, -90deg);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
          opacity: 0;
        }
        54% {
          -webkit-transform: translateZ(-160px) rotate3d(-1, 1, 0, -87deg);
                  transform: translateZ(-160px) rotate3d(-1, 1, 0, -87deg);
          -webkit-animation-timing-function: ease-in-out;
                  animation-timing-function: ease-in-out;
          opacity: 1;
        }
        100% {
          -webkit-transform: translateZ(0) rotate3d(-1, 1, 0, 0);
                  transform: translateZ(0) rotate3d(-1, 1, 0, 0);
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
        }
      }
      @keyframes slit-in-diagonal-2 {
        0% {
          -webkit-transform: translateZ(-800px) rotate3d(-1, 1, 0, -90deg);
                  transform: translateZ(-800px) rotate3d(-1, 1, 0, -90deg);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
          opacity: 0;
        }
        54% {
          -webkit-transform: translateZ(-160px) rotate3d(-1, 1, 0, -87deg);
                  transform: translateZ(-160px) rotate3d(-1, 1, 0, -87deg);
          -webkit-animation-timing-function: ease-in-out;
                  animation-timing-function: ease-in-out;
          opacity: 1;
        }
        100% {
          -webkit-transform: translateZ(0) rotate3d(-1, 1, 0, 0);
                  transform: translateZ(0) rotate3d(-1, 1, 0, 0);
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
        }
      }
      